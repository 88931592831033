<template>
    <div class="tableBox">
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../sn-table-group-common';
import { selfData } from './sn-table-group-record';
import commonMethodMixin from '../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'record',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            keyword: '', // 关键字
            handleTime: [],
            kind: "",
            exportItemId: 'record'
        };
    },
    mounted() {
        this.getAllUserList();
    },
    methods: {
        ...mapActions([
            "GetCustomAutoHistory",
            "getUserList"
        ]),
        async getAllUserList() {
            let res = await this.getUserList({
                pageIndex: 1,
                pageSize: 999
            });
            this.tableGroupAttributes.snSearch.selectData[0].list = res.Data;
        },
        async getTableData() {
            let time = this.handleTime;
            let start = "";
            let end = "";
            if (time && time.length === 2) {
                start = time[0];
                end = time[1]
            }
            let res = await this.GetCustomAutoHistory({
                keyword: this.keyword,
                userId: this.userId,
                kind: this.kind,
                start,
                end,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu
            });
            this.tableGroupAttributes.snTable.table.data = res.DATA;
            this.tableGroupAttributes.snPage.count = Number(res.TOTAL);
            this.tableGroupAttributes.loading = false;
        },
    },
};
</script>
<style lang="scss">
.sn_search .sn_seatch_item .time.option.record-handle-time {
    width: 230px;
    .el-range-separator {
        padding: 0;
        width: 10%;
    }
}
</style>