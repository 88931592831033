const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'id',
        },
        columns: {
            // 数据列
            columnsData: [
            {
                label: "姓名",
                prop: "USERNAME"
            },
            {
                label: "来访IP",
                prop: "REMOTEIP"
            },
            {
                label: "访问系统",
                prop: "SYSTEMNAME"
            },
            {
                label: "访问模块",
                prop: "MODULENAME"
            },
            {
                label: "操作项",
                prop: "ITEMNAME"
            },
            {
                label: "用户操作",
                prop: "KIND_DESC"
            },
            {
                label: "操作时间",
                minWidth: 140,
                prop: "CREATED"
            }
            ],
            // 不要操作栏
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        selectData: [{
            label: '姓名',
            clearable: true,
            placeholder: "请选择姓名",
            list: [],
            optionLabel: 'REALNAME',
            optionValue: 'ID',
            value: 'userId',
            operateType: 'search',
            isShow: true
        }, {
            label: '用户操作',
            clearable: true,
            placeholder: "请选择用户操作",
            list: [{
                NAME: "编辑",
                ID: "1"
            }, {
                NAME: "删除",
                ID: "3"
            }, {
                NAME: "登录",
                ID: "4"
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'kind',
            operateType: 'search',
            isShow: true
        }],
        dateData: [{
            label: '操作时间',
            isShow: true,
            startPlaceholder: "开始时间",
            endPlaceholder: "结束时间",
            value: 'handleTime',
            type: "daterange",
            operateType: 'search',
            rangeSeparator: "至",
            class: "record-handle-time"
        }]
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };